declare global {
  interface Window {
    __PRERENDER_INJECTED: boolean
  }
  interface ace {
    Editor: any
  }
}

import type { AccessPermission } from '@/services/ide/projects.service'
import type { AI_TAB } from '@/utils/ai'
import { FILE_MESSAGE_TYPE } from '@/utils/ide'
import { type IMeta } from '@/utils/meta'
import { EXECUTION_LIMIT } from '@/utils/sharedData/plans'
import { Boarding } from 'boarding.js'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

type TActiveItem = {
  parent?: string
  name?: string
  content?: string
  dirtyActions?: object[]
  codeChanged?: boolean
  isDirty?: boolean
  id?: number
}
type TFileMessage = {
  type: (typeof FILE_MESSAGE_TYPE)[keyof typeof FILE_MESSAGE_TYPE]
  message: string | null
}

export type TIdeSettings = {
  fontSize: number
  savedTheme: string
  theme: string
  defaultLanguage: string
  defaultVersionIndex: number
  defaultExecutionMode: boolean
  autoSaveOnExecute: boolean
  autoCompleteCode: boolean
}

export type TExecutionHistory = {
  script: string
  args: string | null
  stdin: string | null
  libs: string[]
  output: string
  executedAt: number
  versionIndex: number
}

export type THtmlExecutionHistory = {
  htmlCode: string
  executedAt: number
}

export type IPageDetail = {
  currentPage: number // currentPageIndex
  totalItems: number
  totalPages: number
}

export const useIdeStore = defineStore('ide', () => {
  const isDefaultIde = ref<boolean>(false)
  const isIDE = ref<boolean>(false)
  const mobileTab = ref<string | null>(null)
  const mobileHeightLessThan690 = ref<boolean>(false)
  const sideBarCollapsed = ref<boolean>(true)
  const settingPopupHs = ref<string | null>(null)
  const showStartCodingModal = ref<boolean>(false) /* true if StartCodingModal is visible */

  const syncInProgress = ref<boolean>(false)
  const editingInProcess = ref<boolean>(false)
  const routeMeta = ref<IMeta | null>(null)
  const ideMeta = ref<IMeta | null>(null)
  const fontSize = ref<number>(12)
  const ideSplit = ref<any | null>(null)
  const codeEditor = ref<ace['Editor'] | null>(null)
  const codeUpdated = ref<boolean>(false)
  const outputEditor = ref<ace['Editor'] | null>(null)
  const outputHistory = ref<string>('')
  const project = ref<any | null>(null)
  const activeItem = ref<TActiveItem>({})
  const syncErrorMessages = ref<string | null>('')
  const projectKey = ref<string | boolean>(false)

  const interactiveMode = ref<boolean>(true)
  const versionIndex = ref<number>(-1)
  const args = ref<string | null>(null)
  const stdin = ref<string | null>(null)
  const libraries = ref<string[]>([])
  const executionTime = ref<string | null>(null)
  const memory = ref<number | null>(null)
  const cpuTime = ref<number | null>(null)
  const errorAfterExecute = ref<boolean>(false)
  const aiIsLoading = ref<boolean>(false)
  const aiResponse = ref<string | null>(null)
  const aiTabSelected = ref<AI_TAB | null>(null)
  const aiGenerateCodeReq = ref<string>('')
  const aiGenerateCodeRes = ref<string | null>(null)

  const autoSaveOn = ref<boolean>(false)

  const isCodeExecuting = ref<boolean>(false)
  const socketClient = ref<any | null>(null)
  const wsNextId = ref<number>(0)
  const socketConnected = ref<boolean>(false)

  const uploadInProgress = ref<boolean>(false)
  const maxUploads = ref<number>(5)
  const maxFileSize = ref<number>(5000000)
  const inputFiles = ref<string[]>([])
  const isIdeSettingsCompleted = ref<boolean>(false)
  const ideSettings = ref<TIdeSettings | null>(null)
  const outputFiles = ref<string[]>([])
  const fileUploadMessages = ref<TFileMessage | null>(null)

  const projectTreeCollapsed = ref<boolean>(false)
  const pageDetail = ref<IPageDetail | null>(null)
  const projects = ref<any[]>([])
  const selectedProject = ref<any | null>(null)
  const projectEditor = ref<ace['Editor'] | null>(null)

  const storage = ref<any | null>(null)
  const isLocalStoreEnabled = ref<boolean>(false)
  const executionHistory = ref<TExecutionHistory[] | THtmlExecutionHistory[] | null>(null)
  const selectedExecutionHistory = ref<TExecutionHistory | THtmlExecutionHistory | null>(null)
  const executeCodeEditor = ref<ace['Editor'] | null>(null)
  const executeOutputEditor = ref<ace['Editor'] | null>(null)
  const advCompilerDatabaseEditor = ref<ace['Editor'] | null>(null)

  const copyEditor = ref<ace['Editor'] | null>(null)
  const isCopied = ref<boolean>(false)
  const downloadEditor = ref<ace['Editor'] | null>(null)
  const isDownloadSuccess = ref<string | null>(null)
  const isDownloadFailed = ref<string | null>(null)

  const openEditor = ref<ace['Editor'] | null>(null)
  const importedFromFiles = ref<boolean>(false)

  const isImportSuccess = ref<string | null>(null)
  const isImportFailed = ref<string | null>(null)

  const printBlocker = ref<boolean>(false)
  const fullScreen = ref<boolean>(false)

  const ideAndJdroidIsSplit = ref<boolean>(false)
  const inputAndFileUploadsIsSplit = ref<boolean>(false)
  const libManagerAndLibIsSplit = ref<boolean>(false)

  const blocklyWorkspace = ref<any | null>(null)
  const blocklyEditorLanguages = ref<any[]>([])

  const shareNotFound = ref<boolean>(false)
  const shareNotFoundHttpError = ref<string | null>(null)
  const sharedId = ref<string | null>(null)
  const collabId = ref<string | null>(null)
  const genAccessId = ref<string | null>(null)
  const genAccessPermission = ref<AccessPermission | null>(null)

  const isEmbedded = ref<boolean>(false)
  const pymChild = ref<any | null>(null)

  const isGuru = ref<boolean>(false)

  const htmlDoctypeEditor = ref<ace['Editor'] | null>(null)
  const htmlHeadEditor = ref<ace['Editor'] | null>(null)
  const htmlBodyEditor = ref<ace['Editor'] | null>(null)
  const htmlJsEditor = ref<ace['Editor'] | null>(null)
  const htmlCssEditor = ref<ace['Editor'] | null>(null)

  const openProjectID = ref<string | null>(null)
  const isSharedAdvancedHTML = ref<boolean>(false)

  const colorMode = ref<string | null>(null)

  const showProjectCodingModal = ref<boolean>(false)

  const resizeSettingsBar = ref<boolean>(false)

  const uniqueActiveItemAdvIde = ref<any[]>([])

  const ideFullScreen = ref<boolean>(false)

  const showSettingSideBarMobile = ref<boolean>(true)

  const currentIFrameSourceBrowser = ref<string>('')

  const ideFullScreenMaximizedState = ref<boolean>(false)

  const callProjectSync = ref<boolean>(false)

  const boardingInstance = ref<Boarding | null>(null)

  const boardingResetTab = ref<boolean>(false)

  const boardingAfterLogin = ref<boolean>(false)

  /**
   * Indicates whether the project is running in a pod for advanced compiler or advanced database
   */
  const podReady = ref<boolean>(false)

  /**
   * Indicates whether the initial service was running, useful for reloads
   */
  const initServiceRunning = ref<boolean>(false)

  /**
   * Indicates whether the project is running in a pod for advanced compiler or advanced database
   */
  const advanceServiceRunning = ref<boolean>(false)

  /**
   * Tracks user-initiated service state changes via UI interactions
   */
  const userStartedService = ref<boolean>(false)

  const userStartedServiceUsingExecute = ref<boolean>(false)

  const userStoppedServiceUsingStopExecution = ref<boolean>(false)

  const executionLimit = ref<EXECUTION_LIMIT | null>(null)

  const isIdeView = computed(() => {
    return isIDE.value
  })
  const currentMobileTab = computed(() => {
    return mobileTab.value
  })
  const isSettingPopupHs = computed(() => {
    return settingPopupHs.value
  })
  const isAdvanced = computed(() => {
    return ideMeta.value?.isAdvanced || false
  })
  const isShared = computed(() => {
    return routeMeta.value?.isShared || false
  })
  const isInstantShare = computed(() => {
    return ideMeta.value?.isInstantShare || false
  })
  const isAceLanguageCode = computed(() => {
    if (ideMeta.value?.language === 'blockly') return 'python'
    if (ideMeta.value?.aceCode) return ideMeta.value.aceCode
    return ideMeta.value?.language
  })
  const isLanguage = computed(() => {
    return routeMeta.value?.language || ideMeta.value?.language || ''
  })
  const isLangDisplayName = computed(() => {
    return routeMeta.value?.langDisplayName || ideMeta.value?.langDisplayName || ''
  })
  const isProject = computed(() => {
    return project.value || null
  })
  const isProjectId = computed(() => {
    return project.value?.id || null
  })
  const isPlusVersions = computed(() => {
    if (ideMeta.value?.plusVersions && ideMeta.value?.plusVersions.length > 0) return true
    return false
  })
  const isPlusVersionsList = computed(() => {
    if (isPlusVersions.value) return ideMeta.value?.plusVersions || []
    return []
  })
  const isBasicVersions = computed(() => {
    return ideMeta.value?.versions || []
  })
  const isVersions = computed(() => {
    if (isAdvanced.value && isPlusVersions.value) {
      return isPlusVersionsList.value || []
    } else {
      return ideMeta.value?.versions || []
    }
  })

  const isAutoSaveOn = computed(() => {
    return autoSaveOn.value
  })
  const isWsNextId = computed(() => {
    return wsNextId.value
  })
  const isSocketConnected = computed(() => {
    return socketConnected.value
  })
  const isUploadInProgress = computed(() => {
    return uploadInProgress.value
  })
  const isMaxUploads = computed(() => {
    return maxUploads.value
  })
  const isMaxFileSize = computed(() => {
    return maxFileSize.value
  })
  const isInputFiles = computed(() => {
    return inputFiles.value
  })
  const isOutputFiles = computed(() => {
    return outputFiles.value
  })
  const isFileUploadMessages = computed(() => {
    return fileUploadMessages.value
  })
  const isProjects = computed(() => {
    return projects.value
  })
  const isSelectedProject = computed(() => {
    return selectedProject.value
  })
  const isStorage = computed(() => {
    return storage.value || null
  })
  const isExecutionHistory = computed(() => {
    return executionHistory.value
  })
  const isSelectedExecutionHistory = computed(() => {
    return selectedExecutionHistory.value
  })
  const isImportedFromFiles = computed(() => {
    return importedFromFiles.value
  })
  const isPrintBlocker = computed(() => {
    return printBlocker.value
  })
  const isFullScreen = computed(() => {
    return fullScreen.value
  })
  const isCodeUpdated = computed(() => {
    return codeUpdated.value
  })
  const isBlocklyWorkspace = computed(() => {
    return blocklyWorkspace.value
  })
  const isBlocklyEditorLanguages = computed(() => {
    return blocklyEditorLanguages.value
  })
  const isBlockly = computed(() => {
    return (
      routeMeta.value?.language === 'blockly' ||
      ideMeta.value?.language === 'blockly' ||
      project.value?.language === 'blockly'
    )
  })
  const isBlocklyLanguages = computed(() => {
    return ideMeta.value?.blocklyLanguages || []
  })
  const isBlocklyLanguageVersions = computed(() => {
    return ideMeta.value?.blocklyLanguageVersions || []
  })
  const isShareId = computed(() => {
    return sharedId.value || null
  })
  const isCollabId = computed(() => {
    return collabId.value || null
  })
  const isHtml = computed(() => {
    return routeMeta.value?.language === 'html' || ideMeta.value?.language === 'html'
  })

  const hasErrorAfterExecute = computed<boolean>(() => {
    return errorAfterExecute.value
  })

  const isSideBarCollapsed = computed<boolean>(() => {
    return sideBarCollapsed.value
  })

  const isAdvancedCompiler = computed(() => {
    return routeMeta.value?.advanceCompiler
  })

  const isAdvancedCompilerDatabase = computed(() => {
    return routeMeta.value?.advanceCompilerDatabase
  })

  /**
   * Check if ace editor is injected in global window object
   * @returns window.ace editor or null
   */
  const isWindowAce = () => {
    return window['ace'] || null
  }

  /**
   * Set the default ide
   * @param value - The value to set
   */
  const setIsDefaultIde = (value: boolean) => {
    isDefaultIde.value = value
  }
  /**
   * Set the ide view
   * @param value - The value to set
   */
  const setIsIdeView = (value: boolean) => {
    isIDE.value = value
  }

  /**
   * Set the sidebar collapse
   * @param isCollapsed - The value to set
   */
  const setSideBarCollapsed = (isCollapsed: boolean) => {
    sideBarCollapsed.value = isCollapsed
  }

  /**
   * Set the mobile ide tab
   * @param tabId - The mobile tab constant string to set
   */
  const setMobileTab = (tabId: string | null) => {
    mobileTab.value = tabId
  }
  /**
   * Set the mobile ide tab
   * @param isLessThan690 - True or False
   */
  const setMobileHeightLessThan690 = (isLessThan690: boolean) => {
    mobileHeightLessThan690.value = isLessThan690
  }
  /**
   * Set the setting popup hs
   * @param value - The value to set
   */
  const setSettingPopupHs = (value: string | null) => {
    settingPopupHs.value = value
  }
  /**
   * Set editing in progress
   * @param value - The value to set
   */
  const setEditingInProgress = (value: boolean) => {
    editingInProcess.value = value
  }
  /**
   * Set the ide meta
   * @param meta - The meta to set
   */
  const setRouteMeta = (meta: IMeta) => {
    routeMeta.value = meta
  }
  /**
   * Set the ide meta
   * @param meta - The meta to set
   */
  const setIdeMeta = (meta: IMeta) => {
    ideMeta.value = meta
  }
  /**
   * set font size
   * @param size - The size to set
   */
  const setFontSize = (size: number) => {
    fontSize.value = size
  }
  /**
   * Set the project
   * @param data - The project to set
   */
  const setProject = (data: any | null) => {
    project.value = data
  }
  /**
   * Set the project
   * @param isCollapsed - True = collapsed, False = open
   */
  const setProjectTreeCollapsed = (isCollapsed: boolean) => {
    projectTreeCollapsed.value = isCollapsed
  }
  /**
   * Recursively searches through the provided tree structure to find an item that matches the given home path.
   * @param children - The array of child objects to search through. Each child object represents a file or folder in the tree structure.
   * @param home - The full path to the item that should be set as active, e.g., '/MyClass.java' or '/src/App.jsx'.
   * @returns - Returns the item object that matches the `home` path, or `null` if no match is found.
   */
  const findActiveItem = (children: any[], home: string): any | null => {
    for (const child of children) {
      const fullPath = (child.parent + '/' + child.name).replace(/\/\//g, '/')
      if (fullPath === home) {
        return child
      }
      if (child.children && child.children.length > 0) {
        const result = findActiveItem(child.children, home)
        if (result) {
          return result
        }
      }
    }
    return null
  }

  /**
   * Set the active item. usually done when init
   */
  const setActiveItem = () => {
    activeItem.value = isAdvancedCompiler.value
      ? findActiveItem(project.value?.treeData.children, project.value?.home)
      : project.value?.treeData.children.find((o: any) => {
          return '/' + o.name === project.value.home
        })
  }
  /**
   * Set the project key
   * @param key - The key to set
   */
  const setProjectKey = (key: string | boolean) => {
    projectKey.value = key
  }
  /**
   * Set the sync error messages. will be displayed in the project tree
   * @param value - The value to set
   */
  const setSyncErrorMessages = async (value: string | null) => {
    syncErrorMessages.value = value
    await new Promise((resolve) => setTimeout(resolve, 8000))
    syncErrorMessages.value = null
  }
  /**
   * reset the editor
   */
  const resetEditor = () => {
    args.value = ''
    stdin.value = ''
    libraries.value = []
    executionTime.value = null
    memory.value = 0
    cpuTime.value = 0
  }
  /**
   * reset the execution time
   */
  const resetExecutionTime = () => {
    executionTime.value = null
    memory.value = null
    cpuTime.value = null
  }
  /**
   * Set the execution time
   * @param value - The value to set
   */
  const setExecutionqTime = (value: string) => {
    executionTime.value = value
  }
  /**
   * Set if the shared html project is advanced
   * @param value - The value to set
   */
  const setIsSharedAdvancedHTML = (value: boolean) => {
    isSharedAdvancedHTML.value = value
  }
  /**
   * set the initial version index
   * @param startCodingVersion of start coding version 'string'
   */
  const initVersionIndex = (startCodingVersion: string | null) => {
    if (isGuru.value) return
    if (isAdvanced.value && isPlusVersions.value) {
      if (
        (ideSettings.value?.defaultVersionIndex || ideMeta.value?.defaultVersion) &&
        ideMeta.value?.versions &&
        ideMeta.value?.versions.length > 0
      ) {
        if (ideSettings.value?.defaultVersionIndex && isIdeView.value) {
          const index = isPlusVersionsList.value.findIndex((v: string) => {
            return v === ideMeta.value?.versions![ideSettings.value?.defaultVersionIndex!]
          })
          versionIndex.value = index > -1 ? index : isPlusVersionsList.value.length - 1
        } else {
          const index = isPlusVersionsList.value.findIndex((v: string) => {
            return v === ideMeta.value?.versions![ideMeta.value?.defaultVersion!]
          })
          versionIndex.value = index > -1 ? index : isPlusVersionsList.value.length - 1
        }
      } else versionIndex.value = isPlusVersionsList.value.length - 1
    } else {
      if (isVersions.value.length > 0) {
        if (ideSettings.value?.defaultVersionIndex && isIdeView.value) {
          const defaultLanguage = ideSettings.value.defaultLanguage
          if (isLanguage.value === defaultLanguage) {
            versionIndex.value = ideSettings.value.defaultVersionIndex
          } else {
            versionIndex.value = ideMeta.value?.defaultVersion
              ? ideMeta.value?.defaultVersion
              : isVersions.value.length - 1
          }
        } else {
          versionIndex.value = ideMeta.value?.defaultVersion
            ? ideMeta.value?.defaultVersion
            : isVersions.value.length - 1
        }
      } else {
        versionIndex.value = -1
      }
    }
    if (isIdeView.value && startCodingVersion !== null) {
      const index = isVersions.value.findIndex((v: string) => {
        return v === startCodingVersion
      })
      if (index > -1) versionIndex.value = index
    }
  }
  /**
   * Set the version
   * @param index - The version index to set
   */
  const setVersionIndex = (index: number) => {
    versionIndex.value = index
  }

  /**
   * Set the isCodeExecuting
   * @param value - The value to set
   */
  const setisCodeExecuting = (value: boolean) => {
    isCodeExecuting.value = value
  }
  /**
   * Set output files
   * @param files - The files to set
   */
  const setOutputFiles = (files: string[]) => {
    outputFiles.value = files
  }
  /**
   * reset stdin
   */
  const resetStdin = () => {
    stdin.value = null
  }
  /**
   * Set the execution data
   * @param data - The data to set
   */
  const setExcecutionData = (data: any) => {
    executionTime.value = data.executeTime
    memory.value = data.memory
    cpuTime.value = data.cpuTime
    outputFiles.value = data.outputFiles
  }
  /**
   * Set socket next id
   * @param value - The value to set
   */
  const setwsNextId = (value: number) => {
    wsNextId.value = value
  }
  /**
   * Set socket connected
   * @param value - The value to set
   */
  const setSocketConnected = (value: boolean) => {
    socketConnected.value = value
  }
  /**
   * Set upload in progress
   * @param value - The value to set
   */
  const setUploadInProgress = (value: boolean) => {
    uploadInProgress.value = value
  }
  /**
   * Set input files
   * @param files - The files to set
   */
  const setInputFiles = (files: string[]) => {
    inputFiles.value = files
  }
  /**
   * Set ide settings
   * @param settings - The IDE settings
   * @param state ide setting value got or not
   */
  const setIdeSettings = (settings: TIdeSettings | null, state: boolean) => {
    const savedTheme = settings?.theme

    ideSettings.value = settings

    if (ideSettings.value && savedTheme) {
      ideSettings.value.savedTheme = savedTheme
    }

    isIdeSettingsCompleted.value = state
  }
  /**
   * Remove input file
   * @param file - The file to remove
   */
  const removeInputFile = (file: string) => {
    inputFiles.value.splice(inputFiles.value.indexOf(file), 1)
  }
  /**
   * Set file error messages
   * @param type - The value to set
   * @param value - The value to set
   */
  const setFileUploadMessages = async (
    type: (typeof FILE_MESSAGE_TYPE)[keyof typeof FILE_MESSAGE_TYPE],
    value: string | null
  ) => {
    fileUploadMessages.value = {
      type: type,
      message: value
    }
    await new Promise((resolve) => setTimeout(resolve, 5000))
    fileUploadMessages.value = null
  }
  /**
   * Set projects
   * @param data - The data to set
   */
  const setProjects = (data: any[]) => {
    projects.value = data
  }
  /**
   * Set current page
   * @param page of current table
   */
  const setCurrentPage = (page: number) => {
    if (pageDetail.value) {
      pageDetail.value.currentPage = page
    }
  }
  /**
   * Set page details
   * @param data - The data to set
   */
  const setPageDetail = (data: IPageDetail) => {
    pageDetail.value = data
  }
  /**
   * Set selected project
   * @param data - The data to set
   */
  const setSelectedProject = (data: any | null) => {
    selectedProject.value = data
  }
  /**
   * Set the isLocalStoreEnabled
   * @param value - The value to set
   */
  const setIsLocalStoreEnabled = (value: boolean) => {
    isLocalStoreEnabled.value = value
  }
  /**
   * Set the storage
   * @param value - The value to set
   */
  const setStotage = (value: any | null) => {
    storage.value = value
  }
  /**
   * Set the execution history
   * @param value - The value to set
   */
  const setExecutionHistory = (value: any[] | null) => {
    executionHistory.value = value
  }
  /**
   * Set the selected execution history
   * @param value - The value to set
   */
  const setSelectedExecutionHistory = (value: TExecutionHistory | THtmlExecutionHistory | null) => {
    selectedExecutionHistory.value = value
  }
  /**
   * Set the copy editor
   * @param value - The value to set
   */
  const setIsCopied = (value: boolean) => {
    isCopied.value = value
  }
  /**
   * Set the download success message
   * @param value - The value to set
   */
  const setDownloadSuccess = (value: string | null) => {
    isDownloadSuccess.value = value
  }
  /**
   * Set the download failed message
   * @param value - The value to set
   */
  const setDownloadFailed = (value: string | null) => {
    isDownloadFailed.value = value
  }

  /**
   * Set the execute code editor
   * @param value - The value to set
   */
  const setImportedFromFiles = (value: boolean) => {
    importedFromFiles.value = value
  }
  /**
   * Set the import success
   * @param value - The value to set
   */
  const setImportSuccess = (value: string | null) => {
    isImportSuccess.value = value
  }
  /**
   * Set import failed
   * @param value - The value to set
   */
  const setImportFailed = (value: string | null) => {
    isImportFailed.value = value
  }
  /**
   * Set project editable share url
   * @param url - The url to set
   */
  const setEditableShareUrl = (url: string | null) => {
    project.value.url = url
  }
  /**
   * Set project editable share embed url
   * @param embedUrl - The embed url to set
   */
  const setEditableShareEmbedUrl = (embedUrl: string | null) => {
    project.value.embedUrl = embedUrl
  }
  /**
   * Set the pluginId
   * @param pluginId - The value to set
   */
  const setEditableSharePluginId = (pluginId: string | null) => {
    project.value.pluginId = pluginId
  }
  /**
   * Set the isProBundlePlan
   * @param value - The value to set
   */
  const setIsProBundlePlan = (value: boolean) => {
    project.value.isProBundlePlan = value
  }
  /**
   * Set the print blocker
   * @param value - The value to set
   */
  const setPrintBlocker = (value: boolean) => {
    printBlocker.value = value
  }
  /**
   * Set the full screen
   * @param value - The value to set
   */
  const setFullScreen = (value: boolean) => {
    fullScreen.value = value
  }
  /**
   * Set the code updated
   * @param value - The value to set
   */
  const setCodeUpdated = (value: boolean) => {
    codeUpdated.value = value
  }
  /**
   * Set the code editor
   * @param value - The value to set
   */
  const setOutputHistory = (value: string) => {
    outputHistory.value = value
  }
  /**
   * Set the blockly workspace
   * @param value - The value to set
   */
  const setBlocklyWorkspace = (value: any) => {
    blocklyWorkspace.value = value
  }
  /**
   * Set the blockly editor languages
   * @param value - The value to set
   */
  const setBlocklyEditorLanguages = (value: any[]) => {
    blocklyEditorLanguages.value = value
  }
  /**
   * Set the blockly editor languages
   * @param value - The value to set
   */
  const setShareNotFound = (value: boolean) => {
    shareNotFound.value = value
  }
  /**
   * Set shareNotFoundHttpError value
   * @param value - The value to set
   */
  const setShareNotFoundHttpError = (value: string | null) => {
    shareNotFoundHttpError.value = value
  }
  /**
   * Set the shared id
   * @param value - The value to set
   */
  const setSharedId = (value: string | null) => {
    sharedId.value = value
  }
  /**
   * Set the collaborator id
   * @param value - The value to set
   */
  const setCollabId = (value: string | null) => {
    collabId.value = value
  }

  /**
   * @param value set general access id
   */
  const setGenAccessId = (value: string | null) => {
    genAccessId.value = value
  }

  /**
   * @param value set general access permission
   */
  const setGenAccessPermission = (value: AccessPermission | null) => {
    genAccessPermission.value = value
  }

  /**
   * Set the isEmbedded
   * @param value - The value to set
   */
  const setIsEmbedded = (value: boolean) => {
    isEmbedded.value = value
  }
  /**
   * Set the pym child
   * @param value - The value to set
   */
  const setPymChild = (value: any | null) => {
    pymChild.value = value
  }

  /**
   * Set the isGuru
   * @param value - The value to set
   */
  const setIsGuru = (value: boolean) => {
    isGuru.value = value
  }
  /**
   * Set the html expand
   * @param value - The value to set
   */
  const setOpenProjectID = (value: string | null) => {
    openProjectID.value = value
  }

  /**
   * Sets error after execute (Jdoodle AI button and tab)
   * @param hasError - Boolean of any errors after ide execution
   */
  const setHasErrorAfterExecute = (hasError: boolean) => {
    errorAfterExecute.value = hasError
  }

  /**
   * Set interactiveMode
   * @param val boolean
   */
  const setInteractiveMode = (val: boolean) => {
    interactiveMode.value = val
  }

  /**
   * Set autoSaveOn
   * @param val boolean
   */
  const setAutoSaveOn = (val: boolean) => {
    autoSaveOn.value = val
  }
  /**
   * Set color mode
   * @param val color mode value
   */
  const setColorMode = (val: string | null) => {
    colorMode.value = val
  }
  /**
   * Change visibility status of Start Coding Modal
   * @param value to update state
   */
  const setShowStartCodingModal = (value: boolean = false) => {
    showStartCodingModal.value = value
  }

  /**
   * Set the resize setting bar
   * @param value - The value to set
   */
  const setResizeSettingBar = (value: boolean) => {
    resizeSettingsBar.value = value
  }

  /**
   * Set the unique active item
   * @param val item to add
   */
  const setUniqueActiveItemAdvIde = (val: any) => {
    if (!isAdvancedCompilerDatabase.value) {
      const editableTypes = ideMeta.value?.editableTypes || []
      let editable: boolean = false

      for (const type of editableTypes) {
        if (val.name.endsWith(type)) {
          editable = true
          break
        }
      }

      const index = uniqueActiveItemAdvIde.value.findIndex((item) => {
        return item.name === val.name && item.parent === val.parent
      })

      if (editable && index === -1) {
        uniqueActiveItemAdvIde.value.push(val)
      } else {
        uniqueActiveItemAdvIde.value[index] = val
      }
    } else {
      const index = uniqueActiveItemAdvIde.value.findIndex((item) => {
        return item.id === val.id
      })

      if (index === -1) {
        if (!val?.preventUnshift) {
          uniqueActiveItemAdvIde.value.unshift(val)
        }
      } else {
        uniqueActiveItemAdvIde.value[index] = val
      }
    }
  }

  /**
   * Set the active item. usually done when init
   * @param file tab details
   */
  const setActiveFileDataBase = (file: any) => {
    const exists = uniqueActiveItemAdvIde.value.find((item) => {
      return item?.id === file?.id
    })

    activeItem.value = exists
  }

  /**
   * clear and Set the unique active item
   * @param val item to add
   */
  const setCleanUniqueActiveItemAdvIde = (val: any | null = null) => {
    uniqueActiveItemAdvIde.value = []
    if (val) {
      setUniqueActiveItemAdvIde(val)
    }
  }

  /**
   *
   * @param val item to remove
   */
  const removeUniqueActiveItemAdvIde = (val: any) => {
    const filterValue = uniqueActiveItemAdvIde.value.filter((item) => {
      if (!isAdvancedCompilerDatabase.value) {
        return item.name != val.name
      } else {
        return item?.id != val?.id
      }
    })
    uniqueActiveItemAdvIde.value = filterValue
  }

  /**
   * @param newItem item that has been changed
   * @param oldItem old value from dirty action
   */
  const renameUniqueActiveItemAdvIde = (newItem: any, oldItem: any) => {
    uniqueActiveItemAdvIde.value.forEach((item) => {
      if (item.name === oldItem.oldName && item.parent === oldItem.oldParent) {
        item.name = newItem.name
      }
    })
  }

  /**
   *
   * @param val set showSettingSideBarMobile visible state
   */
  const setShowSettingSideBarMobile = (val: boolean) => {
    showSettingSideBarMobile.value = val
  }

  /**
   * Change visibility status of Project Modal
   * @param value to update state
   */
  const setShowProjectCodingModal = (value: boolean = false) => {
    showProjectCodingModal.value = value
  }

  /**
   * update the current url of browser
   * @param value current url of browser
   */
  const setCurrentIframeSourceBrowser = (value: string) => {
    currentIFrameSourceBrowser.value = value
  }

  /**
   * update maximized state
   * @param val maximized state value
   */
  const setIdeFullScreenMaximizedState = (val: boolean) => {
    ideFullScreenMaximizedState.value = val
  }

  /**
   * Sets the value of `callProjectSync`.
   * @param  val - The new value to set for `callProjectSync`.
   */
  const setCallProjectSync = (val: boolean) => {
    callProjectSync.value = val
  }
  /**
   * Sets the value of resetting the tab.
   * @param  val - The new value to set for tab on boarding.
   */
  const setBoardingResetTab = (val: boolean) => {
    boardingResetTab.value = val
  }

  /**
   * Sets the value of showing boarding tab.
   * @param  val - The new value to set for tab on boarding.
   */
  const setBoardingAfterLogin = (val: boolean) => {
    boardingAfterLogin.value = val
  }

  /**
   * Sets the pod ready state
   * @param val - The new value to set for pod ready state
   */
  const setPodReady = (val: boolean) => {
    podReady.value = val
  }

  /**
   * Sets the initial service running state
   * @param val - The new value to set for initial service running state
   */
  const setInitServiceRunning = (val: boolean) => {
    initServiceRunning.value = val
  }

  /**
   * Sets the advance service running state
   * @param val - The new value to set for advance service running state
   */
  const setAdvanceServiceRunning = (val: boolean) => {
    advanceServiceRunning.value = val
  }

  /**
   * Updates the user-initiated service state
   * @param val - Boolean indicating if service was started/stopped by user
   */
  const setUserStartedService = (val: boolean) => {
    userStartedService.value = val
  }

  /**
   * Sets the user-initiated service state using execute
   * @param val - Boolean indicating if service was started using execute
   */
  const setUserStartedServiceUsingExecute = (val: boolean) => {
    userStartedServiceUsingExecute.value = val
  }

  /**
   * Sets the user-initiated service stop state using stop execution
   * @param val - Boolean indicating if service was stopped using stop execution
   */
  const setUserStoppedServiceUsingStopExecution = (val: boolean) => {
    userStoppedServiceUsingStopExecution.value = val
  }

  /**
   * Sets the execution limit
   * @param val - The execution limit value to set
   */
  const setExecutionLimit = (val: EXECUTION_LIMIT) => {
    executionLimit.value = val
  }

  /**
   * Resets the execution limit by setting its value to null
   */
  const resetExecutionLimit = () => {
    executionLimit.value = null
  }

  return {
    isDefaultIde,
    setIsDefaultIde,
    isIdeView,
    setIsIdeView,
    isSideBarCollapsed,
    setSideBarCollapsed,
    currentMobileTab,
    setMobileTab,
    mobileHeightLessThan690,
    setMobileHeightLessThan690,
    isSettingPopupHs,
    setSettingPopupHs,
    syncInProgress,
    editingInProcess,
    setEditingInProgress,
    routeMeta,
    setRouteMeta,
    ideMeta,
    setIdeMeta,
    fontSize,
    setFontSize,
    ideSplit,
    codeEditor,
    outputEditor,
    ideAndJdroidIsSplit,
    inputAndFileUploadsIsSplit,
    libManagerAndLibIsSplit,
    resetEditor,
    resetExecutionTime,
    setExecutionqTime,
    project,
    setProject,
    activeItem,
    setActiveItem,
    projectKey,
    setProjectKey,
    syncErrorMessages,
    setSyncErrorMessages,
    isWindowAce,
    isAdvanced,
    projectTreeCollapsed,
    setProjectTreeCollapsed,
    isShared,
    isInstantShare,
    isAceLanguageCode,
    isLanguage,
    isLangDisplayName,
    isProject,
    isProjectId,
    isPlusVersions,
    isPlusVersionsList,
    isBasicVersions,
    isVersions,
    versionIndex,
    initVersionIndex,
    setVersionIndex,
    interactiveMode,
    setInteractiveMode,
    executionTime,
    cpuTime,
    memory,
    args,
    stdin,
    resetStdin,
    isCodeExecuting,
    setisCodeExecuting,
    isBlockly,
    isBlocklyLanguages,
    isBlocklyLanguageVersions,
    libraries,
    setExcecutionData,
    autoSaveOn,
    isAutoSaveOn,
    setAutoSaveOn,
    socketClient,
    wsNextId,
    isWsNextId,
    setwsNextId,
    isSocketConnected,
    setSocketConnected,
    isUploadInProgress,
    setUploadInProgress,
    isMaxUploads,
    isMaxFileSize,
    isInputFiles,
    setInputFiles,
    removeInputFile,
    isIdeSettingsCompleted,
    ideSettings,
    setIdeSettings,
    isOutputFiles,
    setOutputFiles,
    isFileUploadMessages,
    setFileUploadMessages,
    isProjects,
    setProjects,
    pageDetail,
    setPageDetail,
    setCurrentPage,
    projectEditor,
    isSelectedProject,
    setSelectedProject,
    isLocalStoreEnabled,
    setIsLocalStoreEnabled,
    isStorage,
    setStotage,
    isExecutionHistory,
    setExecutionHistory,
    isSelectedExecutionHistory,
    setSelectedExecutionHistory,
    executeCodeEditor,
    executeOutputEditor,
    copyEditor,
    isCopied,
    setIsCopied,
    downloadEditor,
    isDownloadSuccess,
    setDownloadSuccess,
    isDownloadFailed,
    setDownloadFailed,
    openEditor,
    isImportedFromFiles,
    setImportedFromFiles,
    isImportSuccess,
    setImportSuccess,
    isImportFailed,
    setImportFailed,
    setEditableShareUrl,
    setEditableShareEmbedUrl,
    setEditableSharePluginId,
    setIsProBundlePlan,
    isPrintBlocker,
    setPrintBlocker,
    isFullScreen,
    setFullScreen,
    isCodeUpdated,
    setCodeUpdated,
    outputHistory,
    setOutputHistory,
    isBlocklyWorkspace,
    setBlocklyWorkspace,
    isBlocklyEditorLanguages,
    setBlocklyEditorLanguages,
    isShareId,
    setSharedId,
    isCollabId,
    setCollabId,
    genAccessId,
    setGenAccessId,
    genAccessPermission,
    setGenAccessPermission,
    shareNotFound,
    setShareNotFound,
    shareNotFoundHttpError,
    setShareNotFoundHttpError,
    isEmbedded,
    setIsEmbedded,
    pymChild,
    setPymChild,
    isGuru,
    setIsGuru,
    htmlDoctypeEditor,
    htmlHeadEditor,
    htmlBodyEditor,
    htmlJsEditor,
    htmlCssEditor,
    isHtml,
    setOpenProjectID,
    openProjectID,
    hasErrorAfterExecute,
    setHasErrorAfterExecute,
    aiIsLoading,
    aiTabSelected,
    aiResponse,
    aiGenerateCodeReq,
    aiGenerateCodeRes,
    isSharedAdvancedHTML,
    setIsSharedAdvancedHTML,
    colorMode,
    setColorMode,
    showStartCodingModal,
    setShowStartCodingModal,
    resizeSettingsBar,
    setResizeSettingBar,
    uniqueActiveItemAdvIde,
    setCleanUniqueActiveItemAdvIde,
    setUniqueActiveItemAdvIde,
    removeUniqueActiveItemAdvIde,
    ideFullScreen,
    showSettingSideBarMobile,
    setShowSettingSideBarMobile,
    showProjectCodingModal,
    setShowProjectCodingModal,
    currentIFrameSourceBrowser,
    setCurrentIframeSourceBrowser,
    ideFullScreenMaximizedState,
    setIdeFullScreenMaximizedState,
    renameUniqueActiveItemAdvIde,
    isAdvancedCompiler,
    isAdvancedCompilerDatabase,
    advCompilerDatabaseEditor,
    callProjectSync,
    setCallProjectSync,
    setActiveFileDataBase,
    boardingInstance,
    setBoardingResetTab,
    boardingResetTab,
    boardingAfterLogin,
    setBoardingAfterLogin,
    podReady,
    setPodReady,
    advanceServiceRunning,
    setAdvanceServiceRunning,
    initServiceRunning,
    setInitServiceRunning,
    executionLimit,
    setExecutionLimit,
    resetExecutionLimit,
    userStartedService,
    setUserStartedService,
    userStartedServiceUsingExecute,
    setUserStartedServiceUsingExecute,
    userStoppedServiceUsingStopExecution,
    setUserStoppedServiceUsingStopExecution
  }
})
